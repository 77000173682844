@import "variables";
html {
    font-size: 100px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow-x: hidden;
}

body {
    font-size: 0.14rem;
    line-height: 1.7;
    color: #555;
}

*,
*:before,
*:after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}

img,
video {
    max-width: 100%;
}

div,
a,
img {
    -webkit-tap-highlight-color: transparent;
}

body,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
p,
form {
    margin: 0;
}

ol,
ul {
    margin: 0;
    padding: 0;
}

li {
    list-style: none;
}

a {
    color: inherit;
    text-decoration: none;
    &:hover {
        color: $c;
    }
}