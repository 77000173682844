@import "variables";
@mixin line {
    content: "";
    display: block;
    width: 50px;
    height: 1px;
    background: currentColor;
}

@mixin bg($img, $value: 50%, $color: transparent) {
    background: url(/img/#{$img}.png) $color;
    background-position: $value;
    background-repeat: no-repeat;
}

@mixin col($value: center, $value2: flex-start) {
    -ms-flex-flow: column wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-flow: column wrap;
    -ms-flex-align: $value;
    -webkit-box-align: $value;
    align-items: $value;
    -ms-flex-pack: $value2;
    -webkit-box-pack: $value2;
    justify-content: $value2;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
}

@mixin row($value: flex-start, $value2: space-between) {
    -ms-flex-flow: row wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
    -ms-flex-align: $value;
    -webkit-box-align: $value;
    align-items: $value;
    -ms-flex-pack: $value2;
    -webkit-box-pack: $value2;
    justify-content: $value2;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    width: 100%;
}

@mixin ell2($value: 2) {
    display: -webkit-box;
    -webkit-line-clamp: $value;
    overflow: hidden;
}

@mixin ell {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@mixin shadow($value: $c) {
    -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

@mixin shadow-h($value: $c) {
    -webkit-box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.2);
}

@mixin clear {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}