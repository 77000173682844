@import "variables";
@import "mixin";
div {
    .el-breadcrumb {
        line-height: 50px;
        border-bottom: 1px solid #ddd;
        margin-bottom: 20px;
    }
}

.subpage {
    background: #f7f7f7;
    padding-bottom: 10px;
}

.el-pagination {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.container {
    max-width: $w + 30px;
    width: 100%;
    padding: 0 15px;
    margin: 0 auto;
}

section {
    width: 100%;
    padding: 70px 0;
    overflow: hidden;
    .titlebar {
        width: 100%;
        padding: 10px 0;
        text-align: center;
        margin: 0px 0 10px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        text-transform: uppercase;
        a {
            font-size: 0.12rem;
        }
        .title {
            line-height: 1.4;
            color: $c;
            h3 {
                font-size: 0.24rem;
                font-weight: 400;
            }
        }
        .subtitle {
            font-size: 0.14rem;
            color: #ddd;
            margin-top: 5px;
        }
        .summary {
            color: #999;
        }
    }
}


/*    header */

header {
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    min-height: 90px;
    background: #fff;
    width: 100%;
    .logo {
        min-width: 300px;
        margin: 20px 0;
    }
    .container {
        display: -ms-flexbox;
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -ms-flex-pack: justify;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        -ms-flex-align: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        align-items: center;
        max-width: $w + 60px;
    }
    .box {
        display: -ms-flexbox;
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -ms-flex-align: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        align-items: center;
    }
    nav {
        text-transform: capitalize;
        color: #333;
        -webkit-transition: 1s;
        -o-transition: 1s;
        transition: 1s;
        width: 100%;
    }
    .navbtn {
        @include col;
        position: absolute;
        right: 30px;
        top: 30px;
        width: 40px;
        height: 40px;
        &:hover {
            cursor: pointer;
        }
        span {
            display: block;
            height: 2px;
            width: 40px;
            background: $c;
            margin: 5px 0;
        }
    }
    .nav {
        width: 100%;
        .subnav {
            visibility: hidden;
            background: #fff;
            position: absolute;
            opacity: 0;
            width: 100%;
            -webkit-transition: 0.3s;
            -o-transition: 0.3s;
            transition: 0.3s;
        }
        >li {
            position: relative;
            display: inline-block;
            text-align: center;
            &:hover {
                .subnav {
                    display: block;
                    visibility: visible;
                    opacity: 1;
                }
            }
            &:nth-child(1) {
                &::after {
                    display: none;
                }
            }
            &:after {
                content: "|";
                top: 4px;
                left: 0;
                color: #ddd;
                position: absolute;
            }
            span {
                font-size: 0.12rem;
                opacity: 0.5;
            }
            a {
                margin: 0 0 0 5px;
                padding: 5px 15px;
                display: block;
                &:hover {
                    color: #fff;
                    background: $c;
                    @include shadow;
                }
            }
        }
    }
}


/* footer */

footer {
    .copyright {
        text-align: center;
        padding: 15px 0;
        border-top: 1px solid rgba(#fff, 0.2);
    }
}


/*list*/

.img-box,
.txt-box {
    width: 100%;
}

.list {
    width: 100%;
    -ms-flex-flow: row wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    >span {
        width: 100%;
    }
    .img-box {
        font-size: 0;
        position: relative;
        overflow: hidden;
        height: 0;
        padding-top: -webkit-calc(100% * 0.6);
        padding-top: calc(100% * 0.6);
        background: #fff;
        border: 1px solid #f5f5f5;
        @include row(center, center);
        img {
            pointer-events: none;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }
    .txt-box {
        width: 100%;
        -ms-flex-flow: wrap;
        -webkit-flex-flow: wrap;
        flex-flow: wrap;
        display: -ms-flexbox;
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -ms-flex-align: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        align-items: center;
        position: relative;
    }
    .title {
        font-weight: 400;
        @include ell;
        color: #333;
        line-height: 30px;
    }
    .summary {
        @include ell2;
        color: #999;
        height: 40px;
        font-size: 0.12rem;
    }
    .date {
        color: #999;
        width: 0.7rem;
        font-size: 0.12rem;
        height: 0.2rem;
        overflow: hidden;
    }
    .item {
        a {
            @include row;
        }
        &:hover {
            h3 {
                color: $c;
            }
            img {
                -webkit-transform: scale(1.1);
                -ms-transform: scale(1.1);
                transform: scale(1.1);
            }
        }
    }
}


/*img*/

$num: 3;
.img-list {
    .item {
        border: 1px solid #ebeef5;
        width: -webkit-calc(100% / #{$num} - 20px);
        width: calc(100% / #{$num} - 20px);
        margin: 10px;
        text-align: center;
        &:hover {
            @include shadow;
        }
    }
    .summary {
        display: none;
    }
    .date {
        display: none;
    }
    .txt-box {
        padding: 10px;
        background: #fff;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .more {
        background: $c;
        margin: 10px auto;
        width: 90px;
        line-height: 30px;
    }
}


/*txt*/

.txt-list {
    .item {
        border-bottom: 1px dashed #ddd;
        margin: 10px 10px 10px 0;
        width: -webkit-calc(100% / 1 - 20px);
        width: calc(100% / 1 - 20px);
        padding: 0 0 10px 0;
    }
    .txt-box {
        width: -webkit-calc(100% - 10px);
        width: calc(100% - 10px);
    }
    .title {
        width: -webkit-calc(100% - 0.7rem);
        width: calc(100% - 0.7rem);
        font-size: 0.14rem;
        color: #666;
        a {
            -ms-flex-align: center;
            -webkit-box-align: center;
            -webkit-align-items: center;
            align-items: center;
        }
    }
    .img-box {
        display: none;
        width: 200px;
        padding-top: -webkit-calc(200px * 0.6);
        padding-top: calc(200px * 0.6);
        img {
            -o-object-fit: cover;
            object-fit: cover;
            width: 100%;
        }
    }
}