@charset "utf-8";
@import "variables";
@import "mixin";
@import "reset";
@import "layout";
.home-btn:hover {
  background: red;
}

#root {
  height: 100%;
}
.campus-btn {
  float: right;
  margin-top: 50px; /* display: none; */
}
.courseInfo-box {
  color: #fff;
  .ant-breadcrumb {
    display: inline-block;
  }
  .ant-breadcrumb-separator,
  .ant-breadcrumb-link {
    color: #fff;
  }
  .ant-modal-body {
    font-size: 0.16rem;
  }
  .ant-modal-content {
    border-radius: 20px;
    overflow: hidden;
  }
  .ant-modal-header,
  .ant-modal-content {
    background: #31465b;
  }
  .ant-modal-header {
    border-bottom: 0;
    height: 58px;
  }
  .ant-modal-title {
    text-align: right;
    padding-right: 50px;
    color: #fff;
    text-decoration: underline;
    color: $c;
  }
  .ant-modal-close {
    color: #fff;
    border-radius: 0 0 0 20px;
    background: #3e627c;
  }
  .bg {
    background: #2a4055;
    border-radius: 20px;
  }
  .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: wrap;
    flex-flow: wrap;
    > div {
      width: 100%;
    }
  }
  .col {
    padding: 20px;
    line-height: 2;
    margin: 20px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .col2 {
    margin: 0;
    .col {
      margin: 0 0 20px 0;
      &:nth-child(2) {
        margin: 0;
      }
    }
  }
  .bookData-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: wrap;
    flex-flow: wrap;
    .item {
      width: calc(100% / 2 - 20px);
      margin-bottom: 10px;
    }
    [href] {
      background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#ebf0f6),
        to(#9bcdd4)
      );
      background: linear-gradient(#ebf0f6, #9bcdd4);
    }
    a {
      background: rgba(#fff, 0.3);
      width: 100px;
      line-height: 30px;
      display: inline-block;
      text-align: center;
      color: #333;
      border-radius: 20px;
    }
  }
}

.sound {
  position: absolute;
  right: 30px;
  top: 30px;
  color: #fff;
  font-size: 0.26rem;
  z-index: 2;
  background: rgba(#fff, 0.1);
  padding: 5px 20px;
  border-radius: 20px;
  &:hover {
    cursor: pointer;
  }
}
.full {
  position: absolute;
  right: 140px;
  top: 30px;
  color: #fff;
  font-size: 0.26rem;
  z-index: 2;
  background: rgba(#fff, 0.1);
  padding: 5px 20px;
  border-radius: 20px;
  &:hover {
    cursor: pointer;
  }
}
.mute {
  color: rgba(#fff, 0.3);
}

.page {
  height: 100%;
}

input:-internal-autofill-selected {
  background-color: none;
}

.face-camera-box {
  position: relative;
  margin-top: 80px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  .canvas-face {
    position: absolute;
    max-width: 800px;
    z-index: 1;

    left: 50%;
    top: 50%;

    transform: rotateY(180deg) translate(50%, -50%);
  }
  @-webkit-keyframes rotate {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotate {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes rotate-r {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(-360deg);
      transform: rotate(-360deg);
    }
  }
  @keyframes rotate-r {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(-360deg);
      transform: rotate(-360deg);
    }
  }
  .rotate-r {
    -webkit-animation: rotate-r 15s linear infinite;
    animation: rotate-r 15s linear infinite;
  }
  .bd {
    border-radius: 50%;
    position: absolute;

    /*  transform: scale(0.3); */
    -webkit-transition: 1s;
    transition: 1s;
  }
  .bd1 {
    border: 8px solid transparent;
    border-left: 8px solid #fff;
    border-right: 8px solid #fff;
    padding: 30px;
    width: 670px;
    height: 670px;
  }
  .bd-max {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  .bd2 {
    position: relative;

    height: 420px;
    width: 540px;
    top: 50px;

    overflow: hidden;
  }
  .arc-box {
    width: 680px;
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 680px;
    .waiquan {
      background: url("/img/waiquan2.png") 50% 80px no-repeat;
      background-size: 610px;
      width: 100%;
      height: 650px;

      align-items: center;
      justify-content: center;
      display: flex;
    }
    .tongkong {
      /*  background: url("/img/tongkong.png") 50% no-repeat; */
      width: 570px;
      height: 570px;
    }
    .zhengyanjing {
      /* background: url("/img/zhengyanjing.png") 50% no-repeat; */
      width: 570px;
      height: 570px;
    }
    .arc1 {
      width: 660px;
      height: 660px;
      border: 10px solid transparent;
      border-top: 10px solid $c;
      margin: -330px 0 0 -330px;
    }
    .arc2 {
      background: url("/img/arc2.png") 50% no-repeat;
      width: 570px;
      height: 570px;
      margin: -285px 0 0 -285px;
    }
    .arc3 {
      background: url("/img/arc3.png") 50% no-repeat;
      width: 590px;
      height: 590px;
      margin: -295px 0 0 -295px;
    }
    .arc4 {
      background: url("/img/arc4.png") 50% no-repeat;
      width: 360px;
      height: 360px;
      margin: -180px 0 0 -180px;
    }
    .arc5 {
      background: url("/img/arc5.png") 50% no-repeat;
      width: 190px;
      height: 190px;
      margin: -95px 0 0 -95px;
    }
    .arc {
      position: absolute;
      left: 50%;
      top: 50%;
      border-radius: 50%;
      -webkit-transition: 0s;
      transition: 0s;
      background-size: contain;
      -webkit-animation: rotate 15s linear infinite;
      animation: rotate 15s linear infinite;
    }
    .arc-min {
      opacity: 0;
    }
    .arc-max {
      width: 760px;
      height: 760px;
      margin: -380px 0 0 -380px;
      -webkit-animation: rotate-r 15s linear infinite;
      animation: rotate-r 15s linear infinite;
    }
  }
}

.add-user-modal {
  .ant-modal-content {
    @include bg(modal_bg);
    height: 277px;
    -webkit-box-shadow: none;
    box-shadow: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: wrap;
    flex-flow: wrap;
    > div {
      width: 100%;
      text-align: center;
    }
  }
  .ant-modal-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .ant-btn {
    @include bg(add_user_btn);
    width: 241px;
    height: 72px;
    border: 0;
    font-size: 0.2rem;
    &:hover {
      cursor: pointer;
      -webkit-filter: brightness(1.2);
      filter: brightness(1.2);
    }
  }
  .ant-modal-footer {
    border: 0;
  }
  p {
    color: #03a0ff;
    font-size: 0.3rem;
    text-transform: capitalize;
  }
}

.face-page {
  @-webkit-keyframes hide-name {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @keyframes hide-name {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  .title-en {
    text-align: center;
  }
  .clock-name-list {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    margin-top: 300px;
    background: rgba(#111, 0.1);
    border: 1px solid rgba(#fff, 0.3);
    padding: 20px;
    color: #fff;
    min-width: 200px;
    border-radius: 20px;
    text-align: center;
    font-size: 0.36rem;
    position: relative;
  }
  .show-name {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-animation: hide-name 0.3s 3s linear forwards;
    animation: hide-name 0.3s 3s linear forwards;
  }
}

.add-user-page {
  .footer .user-items .green .img {
    border-color: green;
  }
  .title-en {
    text-align: center;
  }
  .btn {
    background: rgba(#111, 0.1);
    min-width: 180px;
    height: 60px;
    color: #fff;
    margin: 10px;
    border-radius: 30px;
    border: 0;
    font-size: 0.2rem;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    outline: none;
    padding: 0 20px;
    img {
      margin-right: 10px;
    }
    &:active {
      cursor: pointer;
      background: rgba(#fff, 0.1);
    }
  }
  .screenshot-btn {
    text-indent: 10px;
  }

  .right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: wrap;
    flex-flow: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    .add-user-info {
      margin-top: 60px;
      color: #fff;
      background: rgba(#111, 0.1);
      border: 1px solid rgba(#fff, 0.3);
      padding: 20px;
      border-radius: 20px;
      font-size: 0.16rem;
      .ico {
        text-align: center;
        margin-bottom: 10px;
      }
    }
  }
}

.login-page {
  @include bg(login_bg);
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  .box {
    max-width: 570px;
    min-height: 375px;
    @include bg(login_form_bg);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-flow: wrap;
    flex-flow: wrap;
    padding: 30px;
    margin: 0 auto;
  }
  .title-en {
    margin-bottom: 50px;
  }
  .login-form {
    width: 330px;
  }
  .ant-input {
    @include bg(login_input_bg);
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    height: 37px;
    color: #fff;
  }
  .login-form-button {
    @include bg(login_btn_bg);
    width: 100%;
    border: 0;
    height: 37px;
    font-size: 0.2rem;
    &:hover {
      -webkit-filter: brightness(1.2);
      filter: brightness(1.2);
    }
  }
}

.timetable-page {
  $bgc: rgba(#fff, 0.05);
  $bdc: rgba(#fff, 0.1);
  @include bg(login_bg);
  overflow-y: scroll;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  .ant-table-pagination {
    .ant-pagination-item-link,
    a,
    li {
      background: transparent;
      color: #fff;
    }
  }
  .ant-calendar-picker {
    position: relative;
    top: -48px;

    .ant-calendar-picker-input {
      background: transparent;
      color: #fff;
    }
    .ant-calendar-picker-input::-webkit-input-placeholder {
      color: #fff;
    }
    .ant-calendar-picker-icon {
      color: #fff;
    }
  }
  .ant-table-bordered.ant-table-empty .ant-table-placeholder {
    border-color: $bdc;
  }
  .ant-spin-container::after {
    background: $bgc;
  }
  .ant-empty-description {
    color: #fff;
  }
  .ant-table-placeholder {
    background: $bgc;
  }
  .container {
    max-width: 1200px;
  }
  .titlebar {
    text-align: center;
    margin: 40px 0;
  }
  .title-en {
    text-align: center;
    margin-top: 50px;
  }
  .sign-btn {
    @include bg(sign_btn);
    background-size: contain;
    min-width: 80px;
    height: 53px;
    border: 0;
    color: #fff;
    line-height: 53px;
    display: inline-block;
    &:hover {
      -webkit-filter: brightness(1.2);
      filter: brightness(1.2);
    }
  }
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: none;
  }
  .bd-box {
    position: relative;
    .line {
      position: absolute;
      &:after,
      &:before {
        content: "";
        display: block;
        position: absolute;
        background: #fff;
      }
    }
    .lt {
      left: -20px;
      top: -20px;
      &:after {
        width: 27px;
        height: 3px;
      }
      &:before {
        height: 27px;
        width: 3px;
      }
    }
    .rt {
      right: -20px;
      top: -20px;
      &:after {
        width: 27px;
        height: 3px;
        right: 0;
      }
      &:before {
        height: 27px;
        width: 3px;
        right: 0;
      }
    }
    .lb {
      left: -20px;
      bottom: -20px;
      &:after {
        width: 27px;
        height: 3px;
        bottom: 0;
      }
      &:before {
        height: 27px;
        width: 3px;
        bottom: 0;
      }
    }
    .rb {
      right: -20px;
      bottom: -20px;
      &:after {
        width: 27px;
        height: 3px;
        bottom: 0;
        right: 0;
      }
      &:before {
        height: 27px;
        width: 3px;
        bottom: 0;
        right: 0;
      }
    }
  }
  .ant-table-content .ant-table-scroll .ant-table-body,
  tr,
  .ant-table-header {
    background: $bgc;
  }
  .ant-table {
    color: rgba(#fff, 0.8);
  }
  .ant-table-bordered .ant-table-thead > tr > th {
    color: #fff;
    font-size: 0.2rem;
  }
  .ant-table-bordered .ant-table-body > table,
  .ant-table-tbody > tr > td,
  .ant-table-bordered .ant-table-thead > tr > th,
  .ant-table-bordered .ant-table-tbody > tr > td {
    border-color: $bdc;
    font-size: 0.16rem;
  }
}

.audio-box {
  position: absolute;
  top: 20px;
  left: 0;
}

.arc-face-img {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: 32px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 3;
  border-radius: 50%;
  width: 568px;
  height: 568px;
  overflow: hidden;
  img {
    height: 100%;
    width: 757px;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

.face-img {
  border-radius: 50%;
  width: 55px;
  height: 55px;
  position: absolute;
  left: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  top: 50%;
  // z-index: 2;
  -webkit-transition: 2s;
  transition: 2s;
}

.face-bg {
  background: #144d96;
  background-size: cover;
  overflow: hidden;
  height: 100vh;
  > .container {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 1300px;
    -ms-flex-flow: wrap;
    flex-flow: wrap;
    /*   @include bg(face_bg2); */
    height: 100%;
    background-size: 85%;
  }
}

.msg-txt {
  height: 80px;
  text-align: center;
  background-size: contain;
  position: absolute;
  left: 50%;
  top: 80px;
  font-size: 20px;
  color: #fff;
  width: 800px;
  margin-left: -400px;
  z-index: 2;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.msg-red {
  background: url("/img/face_txt_bg2.png") 50% no-repeat;
}

.msg-blue {
  background: url("/img/face_txt_bg1.png") 50% no-repeat;
}
.zhayanjing {
  margin-top: 85px;
  width: 470px;
}
.top {
  width: 254px;
  height: 230px;
  position: absolute;
  z-index: 2;
  left: 50%;
  font-size: 20px;
  top: 50%;
  margin-top: -340px;
  margin-left: 370px;
  color: #1bcaff;
  background: url(/img/mingzi.png) transparent;
  background-size: contain;
  background-position: 0 110px;
  background-repeat: no-repeat
}

.main {
  max-width: 1310px;
  margin: 0 auto;
  width: 100%;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: calc(100% - 140px);
  .right {
    height: 600px;
  }
  .left {
    color: #fff;
    width: 50%;
    display: flex;
    margin-left: 40px;
    height: 600px;
    align-items: center;
    font-size: 0.16rem;
    text-transform: uppercase;
    .title {
      span {
        color: #d89714;
      }
    }
    @-webkit-keyframes rocket {
      from {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
      }
      to {
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
      }
    }
    @keyframes rocket {
      from {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
      }
      to {
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
      }
    }
    .content {
      margin-top: 300px;
      background: rgba(#fff, 0.2);
      padding: 20px 30px;
      border-radius: 20px;
      position: relative;
      &:before {
        @include bg(rocket, 0 0);
        width: 103px;
        height: 147px;
        display: block;
        content: "";
        position: absolute;
        left: -10px;
        top: -60px;
        -webkit-animation: rocket 2s linear infinite alternate;
        animation: rocket 2s linear infinite alternate;
      }
    }
    div {
      margin-bottom: 10px;
    }
    .line {
      background: rgba(#fff, 0.3);
      width: 150px;
      height: 3px;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        display: inline-block;
        background: #fff;
        height: 3px;
        width: 9px;
      }
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        display: inline-block;
        background: #fff;
        height: 3px;
        width: 9px;
      }
    }
  }
  /* .left,
  .right {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  } */
  @-webkit-keyframes radar-rotate {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes radar-rotate {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .center {
    width: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    .animate-box {
      pointer-events: none;
    }
    .ifr-box {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
    .face-iframe {
      width: 800px;
      height: 600px;
    }
    .line {
      @include bg(line_bg);
      position: absolute;
      background-size: 77%;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.add-user-btn {
  height: 72px;
  width: 100%;
}
.left {
  position: relative;
}
.footer {
  .user-items {
    display: flex;
    height: 280px;
    width: 100%;
    position: absolute;
    top: -100px;
    .queue-simple {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
    .item {
      text-align: center;

      position: absolute;
      &:nth-child(1) {
        top: 234px;
        left: -25px;
      }
      &:nth-child(2) {
        top: 153px;
        left: 72px;
      }
      &:nth-child(3) {
        top: 119px;
        left: 196px;
      }
      &:nth-child(4) {
        top: 153px;
        left: 324px;
      }
      &:nth-child(5) {
        top: 230px;
        left: 425px;
      }
      .login0 {
        .txt {
          color: #ffa53a;
        }
      }
      .login1 {
        display: block;
        pointer-events: none;

        .txt {
          color: #e2e2e2;
        }
      }
      .login2 {
        display: block;
        pointer-events: none;

        .txt {
          color: #1bcaff;
        }
      }
    }
    .img {
      width: 90px;
      position: relative;
      overflow: hidden;
      height: 90px;
      border: 5px solid #fff;
      border-radius: 50%;

      img {
        position: absolute;
        left: 50%;
        max-width: 120px;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }
    .txt {
      color: $c;
      font-size: 0.15rem;

      position: absolute;
      left: 50%;
      margin-left: -20px;
      top: -30px;
    }
    .date {
      color: $c;
      font-size: 0.12rem;
    }
  }
}
